import React from 'react';
import Button from '../components/Button';
import { Link } from 'gatsby';
import { SectionTitle } from '../components/Section';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStreetView,
  faCodeBranch,
  faAtom,
  faShieldAlt,
  faChartPie,
  faSitemap
} from '@fortawesome/free-solid-svg-icons';

const WhatWeDo = () => {
  return (
    <div className='px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20'>
      <div className='flex flex-col lg:flex-row'>
        <div className='max-w-xl pr-16 mx-auto mb-10'>
          <SectionTitle
            headingLevel='h1'
            className='text-5xl text-blue mb-6 font-extrabold leading-none'
            variant='left'
          >
            What We Do
          </SectionTitle>
          <p className='mb-6 text-gray-900'>
            Our team here at LightFeather provides enterprise level expertise across multiple
            domains. We specialize in these specific categories in order to provide value quickly
            while building for the future.
          </p>
          <div className='flex items-center'>
            <Link to='/about-us'>
              <Button
                aria-label=''
                className='inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800'
              >
                Learn more
              </Button>
            </Link>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row'>
          <div className='grid gap-5 row-gap-5 sm:grid-cols-2'>
            <div className='max-w-md'>
              <div className='flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50'>
                <FontAwesomeIcon icon={faStreetView} size='4x' />
              </div>
              <div className='mb-2 font-semibold leading-7 text-blue'>
                Human Centered Digital Design
              </div>
              <p className='text-gray-700'>
                We provide human centered design focused on people’s everyday thinking, emotions,
                and behavior. It is a creative approach to problem-solving that involves the
                end-user from the very beginning and places them at the center of the digital design
                process.
              </p>
            </div>
            <div className='max-w-md'>
              <div className='flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50'>
                <FontAwesomeIcon icon={faCodeBranch} size='4x' />
              </div>
              <div className='mb-2 font-semibold leading-7 text-blue'>DevSecOps</div>
              <p className='text-gray-700'>
                We are a leader in all aspects of both architecting and developing software
                applications, including cloud migration and modernization of applications.
              </p>
            </div>
            <div className='max-w-md'>
              <div className='flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50'>
                <FontAwesomeIcon icon={faShieldAlt} size='4x' />
              </div>
              <div className='mb-2 font-semibold leading-7 text-blue'>Cybersecurity</div>
              <p className='text-gray-700'>
                Our company provides a wealth of comprehensive cybersecurity expertise that includes
                such areas as continuous monitoring, SOC, Risk Management, security control
                assessments, pen testing and FISMA compliance.
              </p>
            </div>
            <div className='max-w-md'>
              <div className='flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50'>
                <FontAwesomeIcon icon={faAtom} size='4x' />
              </div>
              <div className='mb-2 font-semibold leading-7 text-blue'>
                Data Science (AI, ML & RPA)
              </div>
              <p className='text-gray-700'>
                We provide capabilities across the realm of data science and analytics fields, to
                include Artificial Intelligence (AI), Machine Learning (ML) and Robotic Process
                Automation (RPA).
              </p>
            </div>
            <div className='max-w-md'>
              <div className='flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-indigo-50'>
                <FontAwesomeIcon icon={faChartPie} size='4x' />
              </div>
              <div className='mb-2 font-semibold leading-7 text-blue'>
                Data Engineering & Business Intelligence
              </div>
              <p className='text-gray-700'>
                We build and nurture robust data infrastructures. By designing, constructing,
                integrating, and maintaining comprehensive data systems, we ensure seamless
                collection, storage, and processing. This translates to reliable and scalable data
                resources, optimized for diverse business needs. Our BI solutions empower
                organizations to derive insights that are not just data-driven, but strategically
                aligned with their growth visions.
              </p>
            </div>

            <div className='max-w-md'>
              <div className='flex items-center justify-center w-16 h-16 mb-4 ml-2 rounded-full bg-indigo-50'>
                <FontAwesomeIcon icon={faSitemap} size='4x' />
              </div>
              <div className='mb-2 font-semibold leading-7 text-blue'>Business Management</div>
              <p className='text-gray-700'>
                We specialize in strategic planning tailored to align with market trends and
                stakeholder expectations, ensuring operational excellence through process
                streamlining and continuous improvement. Our approach extends to nurturing
                leadership pipelines, optimizing talent, and expertly guiding organizations through
                change. Our commitment is to not just navigate present challenges, but to also
                catalyze growth and cultivate a visionary, future-ready organizational ethos.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;

import React from 'react';
import Button from '../components/Button';
import { Card, CardContent } from '../components/Card';
import CareerCard from '../components/CareerCard';
import Layout from '../components/layout/Layout';
import disruptImg from '../images/disrupt.png';
import descriptionImg from '../images/description-icon.png';
import innovateImg from '../images/innovate.png';
import automateImg from '../images/automate.png';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Section, SectionTitle } from '../components/Section';
import BlurFeather from '../images/blurr-feathers.png';
import DescriptionGb from '../images/description-bg.png';
import freefallFeather from '../images/free-fall-feathers.png';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WhatWeDo from '../sections/WhatWeDo';

const App = (props) => (
  <Layout>
    <Helmet>
      <meta charSet='utf-8' />
      <title>LightFeather</title>
    </Helmet>
    <Section bgImage={BlurFeather} bgPosition='bg-right' className='bg-top'>
      <Card className='shadow-none'>
        <CardContent>
          <div className='container mx-auto text-white py-40 '>
            <div className='text-center'>
              <h1 className='text-5xl font-bold leading-none'>
                Innovative, value-driven IT <span className='text-blue-lighter'>solutions</span>
              </h1>
              <p className='text-xl lg:text-2xl mt-6 font-light'>
                Lightfeather is an industry leading consulting firm specializing in DevSecOps and
                system modernization. See how we're changing the world.
              </p>
              <p className='mt-8 md:mt-12 text-xl'>
                <Link to='/about-us'>
                  <Button className='rounded-full' size='lg'>
                    Learn More About Who We Are
                  </Button>
                </Link>
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
    </Section>
    <Section bgImage={DescriptionGb} className='text-white'>
      <SectionTitle headingLevel='h1' className='text-5xl font-bold leading-none'>
        <div>
          Always Ideating<span className='text-blue-lighter'>.</span> Iterating
          <span className='text-blue-lighter'>.</span> Innovating
          <span className='text-blue-lighter'>.</span>
        </div>
      </SectionTitle>
      <Card className='shadow-none'>
        <CardContent>
          <div className='container mx-auto pt-10'>
            <div className='grid grid-cols-3 gap-4'>
              <div className='col-span-3 md:col-span-1 m-auto'>
                <img className='pl-5 max-w-275-px' src={descriptionImg} alt='descriptionImg' />
              </div>
              <div className='col-span-3 md:col-span-2 mx-auto'>
                <Card className='shadow-none border-b-2 md:border-b-0 prose lg:prose-lg text-white '>
                  <p>
                    LightFeather is quickly establishing itself as a recognized, trusted, and
                    innovative technology company. We offer a unique combination of technology,
                    thought leadership, technical capability, and operational excellence.
                  </p>
                  <p>
                    LightFeather provides Cloud, Agile, UI/UX Design, Business Management,
                    DevSecOps, Cybersecurity, Data Engineering and Business Intelligence, and Data
                    Science and Analytics services. We are a powerhouse of innovators, disruptors,
                    and technologists from a broad spectrum of disciplines, transforming data to
                    empower customers with untapped insights.
                  </p>
                  <p>
                    <div>
                      <FontAwesomeIcon icon={faCheckSquare} />
                      {` Service-Disabled Veteran Owned Small Business (SDVOSB)`}
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faCheckSquare} />
                      {` Economically Disadvantaged Woman-owned Small Business (ED-WOSB)`}
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faCheckSquare} />
                      {` SBA certified 8(a)`}
                    </div>
                  </p>
                </Card>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </Section>
    <Section className='bg-gray-200 bg-opacity-50'>
      <WhatWeDo />
    </Section>
    <Section bgImage={freefallFeather}>
      <SectionTitle headingLevel='h1' className='text-5xl font-bold text-blue'>
        Our Services
      </SectionTitle>
      <Card className='shadow-none'>
        <CardContent>
          <p className='text-blue text-xl lg:text-2xl text-center mt-6 m-auto servicesText font-light tracking-wide'>
            Lightfeather is an industry leading consulting firm specializing in DevSecOps and system
            modernization. See how we're changing the world.
          </p>
          <div className='container mx-auto pt-10'>
            <div className='grid grid-cols-1 md:grid-cols-3 mt-12 '>
              <Card className='shadow-none'>
                <img
                  className='w-full max-h-125-px max-w-115-px m-auto mb-5'
                  src={disruptImg}
                  alt='disruptImg'
                />
                <p className='text-4xl text-center text-blue'>Disrupt</p>
                <p className='mt-4 text-black tracking-wider'>
                  We are not afraid to disrupt and innovate to get stuff done with a focus on
                  delivering business value continuously.
                </p>
              </Card>
              <Card className='shadow-none'>
                <img
                  className='w-full max-h-125-px max-w-115-px m-auto mb-5'
                  src={innovateImg}
                  alt='innovateImg'
                />
                <p className='text-4xl text-center text-blue'>Innovate</p>
                <p className='mt-4 text-black tracking-wider'>
                  We focus on delivering efficiencies and automated solutions to help our customers
                  see their business processes in a totally new light.
                </p>
              </Card>
              <Card className='shadow-none'>
                <img
                  className='w-full max-h-125-px max-w-115-px m-auto mb-5'
                  src={automateImg}
                  alt='automateImg'
                />
                <p className='text-4xl text-center text-blue'>Automate</p>
                <p className='mt-4 text-black tracking-wider'>
                  We provide coaching, design, and development of different range of automation for
                  all types of organizations.
                </p>
              </Card>
            </div>
          </div>
        </CardContent>
      </Card>
    </Section>
    <Section className='text-blue bg-primary'>
      <SectionTitle
        headingLevel='h1'
        className='text-5xl font-bold leading-none pt-10 pb-20 text-white'
      >
        Career Opportunities
      </SectionTitle>
      <Card className='shadow-none'>
        <CardContent>
          <div className='container mx-auto'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
              {props.data.allStrapiJob.nodes.map((document) => (
                <CareerCard
                  key={document.id}
                  career={{
                    title: document.jobTitle,
                    content: document.homepageBlurb,
                    id: document.id
                  }}
                ></CareerCard>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>
    </Section>
    <Section>
      <SectionTitle headingLevel='h2' className='text-5xl font-bold leading-none text-blue-darker'>
        Have a Project?
      </SectionTitle>

      <div className='container mx-auto'>
        <div className='grid grid-cols-1'>
          <Card className='shadow-none'>
            <CardContent className='text-xl lg:text-2xl mt-6 font-light text-blue'>
              We’re always looking for new and exciting projects to work on. If you or your company
              is looking for help on your next project, reach out to find out more about how
              LightFeather can be of service.
            </CardContent>
            <CardContent className='text-center mt-8 md:mt-12 text-xl'>
              <a className='iconLinks' href='mailto:sarah.fahden@lightfeather.io'>
                <Button className='rounded-full' size='lg'>
                  Get In Touch
                </Button>
              </a>
            </CardContent>
          </Card>
        </div>
      </div>
    </Section>
  </Layout>
);

export default App;

export const pageQuery = graphql`
  query {
    allStrapiJob {
      nodes {
        id
        jobTitle
        homepageBlurb
        postingBlurb
        qualifications
        applicationLink
      }
    }
  }
`;
